type WebApp = {
    showAlert: (message: string) => void;
    showConfirm: (message: string, callback?: ((ok?: boolean | undefined) => void) | undefined) => void;
    openLink: (url: string) => void;
};

export const $webapp = (): WebApp => {
    const store = useGlobalStore();
    const isTelegram = window.Telegram.WebApp.platform !== 'unknown';
    return {
        showAlert: isTelegram ? window.Telegram.WebApp.showAlert : window.alert,
        showConfirm: isTelegram
            ? window.Telegram.WebApp.showConfirm
            : (message: string, callback?: ((isOK: boolean) => void) | undefined) =>
                callback ? callback(window.confirm(message)) : undefined,
        openLink: isTelegram ? window.Telegram.WebApp.openLink : window.open,
    };
};
